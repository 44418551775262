<template>
	<div>
		<div>
			<div class="d-flex justify-space-between">
				<div class="header__title">{{ title }}</div>
				<div class="header__buttons">
					<v-btn class="header__button" @click="openCreateChapter('Создать раздел')" >Добавить раздел</v-btn>
					<v-btn class="header__button add-directory" @click="openCreateDirectory('Создать справочник')">Добавить справочник</v-btn>
				</div>
			</div>
			<v-dialog  max-width="596px"
				v-model="showChapterDialog"
			>
				<component
					v-if="showChapterDialog"
					:is="dialogProps.component"
					:chaptersList="dialogProps.chaptersList"
					:title="dialogProps.title"
					:chapter="dialogProps.chapter"
					@closeDialog="closeDialog"
					@saveChapter="saveChapter"
				></component>
			</v-dialog>
			<v-dialog  max-width="1068px"
				v-model="showDirectoryDialog"
			>
				<component
					v-if="showDirectoryDialog"
					:is="dialogProps.component"
					:chaptersList="dialogProps.chaptersList"
					:title="dialogProps.title"
					:dictionary="dialogProps.dictionary"
					:dictionaryValues="dialogProps.dictionaryValues"
					@closeDialog="closeDialog"
					@saveDictionary="saveDirectory"
				></component>
			</v-dialog>
		</div>

		<div class="tree-wrapper">
			<vuetify-draggable-treeview
				v-model="dictionariesList"
				:on-move="onMove"
				@input="debouncedStructure"
			>
			<template v-slot:prepend="{ item }">
					<v-icon class="type-icon" v-if="item.type === 'folder'">mdi-folder-open </v-icon>
					<v-icon class="type-icon" v-else>mdi-file-document-outline </v-icon>
				</template>

				<template v-slot:label="{ item }">
					<div class="d-flex">
						<div class="treeview-item">{{ item.name }}</div>
						<v-icon
								class="ml-2"
								small
								color="grey"
								@click="editingHandler(item)"
						>
							mdi-pencil
						</v-icon>
					</div>
				</template>

				<template v-slot:append="{ item }">
					<div class="settings-info">
						<div
							v-if="item.is_hidden === '1'"
							class="settings-item"
							:class="{'hidden-item': item.is_hidden === '1'}"
						>
							{{ item.is_hidden === '1' ? 'значения скрыты' : ''}}
						</div>
						<div
							v-if="item.is_default === '1'"
							class="settings-item"
							:class="{'default-item': item.is_default === '1'}"
						>
							{{ item.is_default === '1' ? 'по умолчанию' : ''}}
						</div>
						<div
							class="settings-item"
							:class="[{'active-item': item.is_active === '1'}, {'not-active-item': item.is_active === '0'}]"
						>
							{{ item.is_active === '1' ? 'доступен' : 'скрыт'}}
						</div>
					</div>
				</template>
			</vuetify-draggable-treeview>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import dialogChapter from './components/dialogChapter.vue'
import dialogDirectory from './components/dialogDirectory.vue'
import debounce from 'lodash/debounce'
import VuetifyDraggableTreeview from '@/components/treeDraggable/DraggableTreeview.vue'

export default {
	metaInfo () {
		return {
			title: this.title
		}
	},
	components: {
		'v-dialog-chapter': dialogChapter,
		'v-dialog-directory': dialogDirectory,
		VuetifyDraggableTreeview: VuetifyDraggableTreeview
	},

	data () {
		return {
			title: 'Справочники CTR',
			dialogProps: {},
			showChapterDialog: false,
			showDirectoryDialog: false,
			chaptersList: [],
			dictionariesList: [],
			sortChanges: [],
			sortedTreePrev: []
		}
	},

	computed: {
		...mapState({
			chapters: state => state.ctr.chapters,
			ctrStructure: state => state.ctr.ctrStructure,
			dictionaryValues: state => state.ctr.dictionaryValues
		})
	},

	async mounted () {
		try {
			await this.getChapters()
			await this.getCtrStructure()
		} catch (error) {
			this.$notify({ type: 'error', title: error.message })
		}
		const copyList = JSON.parse(JSON.stringify(this.ctrStructure))
		this.dictionariesList = this.updateIds(copyList)
		const copyChaptersList = JSON.parse(JSON.stringify(this.chapters))
		this.chaptersList = this.updateIds(copyChaptersList)
		this.sortedTreePrev = JSON.parse(JSON.stringify(this.dictionariesList))
	},

	methods: {
		...mapActions({
			getChapters: 'ctr/getChapters',
			getCtrStructure: 'ctr/getCtrStructure',
			createChapter: 'ctr/createChapter',
			updateChapter: 'ctr/updateChapter',
			createDictionary: 'ctr/createDictionary',
			updateDictionary: 'ctr/updateDictionary',
			getDictionaryValues: 'ctr/getDictionaryValues',
			sendSortChanges: 'ctr/sendSortChanges'
		}),

		debouncedStructure: debounce(async function (sortedTree) {
			this.setSortChanges(sortedTree, this.sortedTreePrev, null)
			if (this.sortChanges.length) {
				try {
					await this.sendSortChanges(this.sortChanges)
					this.sortedTreePrev = JSON.parse(JSON.stringify(sortedTree))
				} catch (error) {
					this.$notify({
						type: 'error',
						title: error.message
					})
				}
				this.sortChanges = []
			}
		}, 2000),

		returnIds (obj) {
			if (obj && typeof obj === 'object') {
				if (obj.id) {
					obj.id = this.extractNumber(obj.id)
				}
				if (obj.parent_id) {
					obj.parent_id = this.extractNumber(obj.parent_id)
				}

				if (Array.isArray(obj.children)) {
					obj.children.forEach(child => this.returnIds(child))
				}
			}
		},

		updateIds (data) {
			const result = []
			for (const item of data) {
				const updatedItem = {
					...item,
					id: item.id.includes('_folder' || '_dictionary') ? item.id : item.id + (item.type === 'folder' ? '_folder' : '_dictionary'),
					parent_id: item.parent_id ? (item.parent_id.includes('_folder') ? item.parent_id : item.parent_id + '_folder') : null
				}

				result.push(updatedItem)
				if (item.type === 'folder' && item.children) {
					updatedItem.children = this.updateIds(item.children)
				}
			}
			return result
		},

		extractNumber (str) {
			if (typeof str !== 'string') {
				str = String(str)
			}
			const match = str.match(/^(\d+)/)
			return match ? match[1] : null
		},

		openCreateChapter (title, item) {
			this.showChapterDialog = true
			this.dialogProps = {
				component: 'v-dialog-chapter',
				chaptersList: this.chaptersList,
				title: title,
				chapter: item
			}
		},

		openCreateDirectory (title, item, dictionaryValues) {
			this.showDirectoryDialog = true
			this.dialogProps = {
				component: 'v-dialog-directory',
				chaptersList: this.chaptersList,
				title: title,
				dictionary: item,
				dictionaryValues: dictionaryValues
			}
		},

		async closeDialog () {
			this.showChapterDialog = false
			this.showDirectoryDialog = false
		},

		async updateStructure () {
			try {
				await this.getCtrStructure()
			} catch (error) {
				this.$notify({
					type: 'error',
					title: error.message
				})
			}
			const copyList = JSON.parse(JSON.stringify(this.ctrStructure))
			this.dictionariesList = this.updateIds(copyList)
		},

		async saveChapter (form) {
			const body = {
				parent_id: !form.chapterParent.length ? null : this.extractNumber(form.chapterParent[0]),
				name: form.chapterName,
				is_active: form.is_active ? '1' : '0',
				is_default: form.is_default ? '1' : '0'
			}
			if (form.id) {
				const requestId = this.extractNumber(form.id)
				body.id = this.extractNumber(requestId)

				try {
					await this.updateChapter(body)
				} catch (error) {
					this.$notify({
						type: 'error',
						title: error.message
					})
				}
			} else {
				try {
					await this.createChapter(body)
				} catch (error) {
					this.$notify({
						type: 'error',
						title: error.message
					})
				}
			}

			try {
				await this.getChapters()
			} catch (error) {
				this.$notify({
					type: 'error',
					title: error.message
				})
			}
			this.updateStructure()
		},

		async saveDirectory (form) {
			const body = {
				folder_id: this.extractNumber(form.chapterParent[0]),
				name: form.chapterName,
				is_active: form.is_active,
				is_default: form.is_default,
				is_hidden: form.is_hidden,
				is_actual: form.is_actual,
				ctr: form.ctr,
				html: form.html || ''
			}
			if (form.id) {
				const requestId = this.extractNumber(form.id)
				body.id = this.extractNumber(requestId)
				try {
					await this.updateDictionary(body)
				} catch (error) {
					this.$notify({
						type: 'error',
						title: error.message
					})
				}
			} else {
				try {
					await this.createDictionary(body)
				} catch (error) {
					this.$notify({
						type: 'error',
						title: error.message
					})
				}
			}
			this.updateStructure()
		},

		async editingHandler (item) {
			if (item.type === 'folder') {
				this.openCreateChapter('Редактировать раздел', item)
			} else {
				const updatedItem = this.extractNumber(item.id)
				try {
					await this.getDictionaryValues(updatedItem)
				} catch (error) {
					this.$notify({
						type: 'error',
						title: error.message
					})
				}
				this.openCreateDirectory('Редактировать справочник', item, this.dictionaryValues)
			}
		},

		onMove (context) {
			if (context && context.draggedContext && context.relatedContext) {
				const futureIndex = context.draggedContext.futureIndex
				const list = context.relatedContext.list
				const isDraggedElementFolder = context.draggedContext.element.type === 'folder'
				if (context.draggedContext.element.is_default === '1' || context.relatedContext.element.is_default === '1') return false
				if (futureIndex !== undefined && futureIndex !== null && list && list.length) {
					if (isDraggedElementFolder) {
						const dictionaryIndex = list.findIndex(item => item.type === 'dictionary')
						if (dictionaryIndex !== -1 && futureIndex >= dictionaryIndex) {
							return false
						}
					} else {
						const folderIndex = list.findLastIndex(item => item.type === 'folder')
						if (folderIndex !== -1 && futureIndex <= folderIndex) {
							return false
						}
					}
				}
			}
		},

		setSortChanges (items, itemsPrev, parentId) {
			if (!items.length || !itemsPrev.length) return
			const sorted = { parent_id: parentId }
			const folders = items.filter(item => item.type === 'folder')
			const foldersPrev = itemsPrev.filter(item => item.type === 'folder')
			const dictionaries = items.filter(item => item.type === 'dictionary')
			const dictionariesPrev = itemsPrev.filter(item => item.type === 'dictionary')

			if (this.sortChanged(folders, foldersPrev)) {
				sorted.folders = folders.map(folder => this.extractNumber(folder.id))
			}
			if (this.sortChanged(dictionaries, dictionariesPrev)) {
				sorted.dictionaries = dictionaries.map(dictionary => this.extractNumber(dictionary.id))
			}
			if (sorted.folders || sorted.dictionaries) {
				this.sortChanges.push(sorted)
			}

			items.map(item => {
				const itemPrev = itemsPrev.find(entity => entity.id === item.id && entity.type === item.type)
				if (item.children && itemPrev && itemPrev.children) {
					this.setSortChanges(item.children, itemPrev.children, this.extractNumber(item.id))
				}
			})
		},

		sortChanged (items, itemsPrev) {
			if (items.length !== itemsPrev.length) {
				return true
			}
			for (let i = 0; i < items.length; i++) {
				if (items[i] && itemsPrev[i] && items[i].id !== itemsPrev[i].id) {
					return true
				}
			}
			return false
		}
	}
}

</script>

<style lang="scss" scoped>
	.header {
		&__title {
			font-size: 24px;
			line-height: 28px;
			font-weight: 700;
		}

		&__button {
			height: 32px;
			margin-left: 5px;
			border-radius: 8px !important;
			box-shadow: none !important;
			text-transform: none !important;
		}
	}

	.add-directory {
		background-color: #2979FF !important;
		color: white;
	}

	.settings-info {
		display: inline-flex;
		gap: 17px;
		font-size: 12px;
	}

	.tree-wrapper {
		margin-top: 15px;
	}

	.item {
		display: flex;
	}

	.type-icon {
		margin-right: 6px;
	}

	.settings-item {
		padding: 4px 10px;
		border-radius: 4px;
	}

	.hidden-item {
		background-color:  #FFEBEB;
		color: #C25757;

	}

	.default-item {
		background-color:  #EEEBFF;
		color: #6B57C2;
	}

	.active-item {
		background-color:  #EBFFEE;
		color: #57C290;
	}

	.not-active-item {
		background-color: #FFF5E0;
		color: #D27600;
	}
</style>
