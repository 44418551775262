<template>
	<v-card>
		<v-card-title class="pt-9 pl-9">{{ title }}</v-card-title>
		<v-card-text class="px-9 pb-9">
			<v-form
				class="form"
				ref="form"
				v-model="formValid"
				@submit.prevent="submitForm"
			>
				<v-container class="container">
					<div class="form-part" >
						<v-text-field
							:rules="[rules.required]"
							label="Название справочника"
							outlined
							hide-details="auto"
							v-model="form.chapterName"
						/>
						<div class="tree-label" :class="{'invalid': !form.chapterParent.length}">Родительский раздел
							<v-treeview
								v-if="chaptersList && chaptersList.length"
								:items="chaptersList"
								:active.sync="form.chapterParent"
								item-key="id"
								activatable
							>
								<template v-slot:prepend="{}">
									<v-icon >mdi-folder-open</v-icon>
								</template>
							</v-treeview>
						</div>
						<span>Справочник доступен пользователям?</span>
						<v-radio-group
							class="mt-0"
							v-model="form.is_active"
							mandatory
							>
							<v-radio
								label="скрыт"
								:value="false"
							></v-radio>
							<v-radio
								label="доступен"
								:value="true"
							></v-radio>
						</v-radio-group>
						<span>Справочник имеет статус "по умолчанию"?</span>
						<v-radio-group
							class="mt-0"
							v-model="form.is_default"
							mandatory
							>
							<v-radio
								label="нет"
								:value="false"
							></v-radio>
							<v-radio
								label="да"
								:value="true"
							></v-radio>
						</v-radio-group>
						<span>Показывать значения CTR пользователям?</span>
						<v-radio-group
							class="mt-0"
							v-model="form.is_hidden"
							mandatory
							>
							<v-radio
								label="скрыты"
								:value="true"
							></v-radio>
							<v-radio
								label="показывать"
								:value="false"
							></v-radio>
						</v-radio-group>
						<span>Справочник устарел?</span>
						<v-radio-group
							class="mt-0"
							v-model="form.is_actual"
							mandatory
							>
							<v-radio
								label="нет"
								:value="true"
							></v-radio>
							<v-radio
								label="да"
								:value="false"
							></v-radio>
						</v-radio-group>
						<v-textarea
							label="HTML"
							auto-grow
							rows="1"
							outlined
							hide-details="auto"
							v-model="form.html"
						/>
					</div>
					<div class="table-part">
						<table class="table">
							<thead class="table__header">
							<tr >
								<th class="table__header-item">Позиция</th>
								<th class="table__header-item">CTR Яндекс</th>
								<th class="table__header-item">CTR Google</th>
							</tr>
							</thead>
							<tbody>
							<tr v-for="element in Array.from(Array(11).keys())" :key="element">
								<td class="table__item">{{ element }}</td>
								<td class="table__item">
								<div class="input-container">
									<input class="input-ctr" v-model.number="form.ctr.yandex[element]" :disabled="dictionary">
									<span class="percent-sign">%</span>
								</div>
								</td>
								<td class="table__item">
								<div class="input-container">
									<input class="input-ctr" v-model.number="form.ctr.google[element]" :disabled="dictionary">
									<span class="percent-sign">%</span>
								</div>
								</td>
							</tr>
							</tbody>
						</table>
					</div>
				</v-container>
				<div class="buttons">
					<v-btn
						class="button cancel-btn"
						@click="closeDialog"
					>
						Отмена
					</v-btn>
					<v-btn
						class="button"
						type="submit"
						:disabled="!hasChanges"
					>
						Сохранить
					</v-btn>
				</div>
			</v-form>
		</v-card-text>
	</v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	props: {
		chaptersList: {
			type: Array,
			default: () => []
		},
		title: {
			type: String
		},
		dictionary: {
			type: Object
		},
		dictionaryValues: {
			type: Object
		}
	},
	data () {
		return {
			form: {
				chapterName: undefined,
				chapterParent: [],
				is_active: false,
				is_default: false,
				is_hidden: true,
				is_actual: true,
				ctr: {
					yandex: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
					google: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
				},
				html: null
			},
			rules: {
				required: value => !!value
			},
			formValid: false
		}
	},

	mounted () {
		if (this.dictionary) {
			this.form = {
				...this.form,
				chapterName: this.dictionary.name,
				chapterParent: [`${this.dictionary.parent_id}`],
				is_active: this.dictionary.is_active === '1',
				is_default: this.dictionary.is_default === '1',
				is_hidden: this.dictionary.is_hidden === '1',
				is_actual: this.dictionary.is_actual === '1',
				id: this.dictionary.id,
				html: this.dictionary.html
			}
		} else {
			this.form.chapterParent.push(this.defaultChapterId + '_folder')
		}
		if (this.dictionaryValues) {
			this.form.ctr = JSON.parse(JSON.stringify(this.dictionaryValues))
		}
	},
	computed: {
		...mapGetters({
			defaultChapterId: 'ctr/defaultChapterId'
		}),
		hasChanges () {
			if (this.dictionary) {
				return this.form.chapterName !== this.dictionary.name ||
				this.form.chapterParent[0] !== this.dictionary.parent_id ||
				this.form.is_active !== (this.dictionary.is_active === '1') ||
				this.form.is_default !== (this.dictionary.is_default === '1') ||
				this.form.is_actual !== (this.dictionary.is_actual === '1') ||
				this.form.is_hidden !== (this.dictionary.is_hidden === '1') ||
				this.form.html !== this.dictionary.html
			}
			return true
		}
	},

	methods: {
		resetForm () {
			this.form = {
				chapterName: undefined,
				chapterParent: [this.defaultChapterId + '_folder'],
				is_active: false,
				is_default: false,
				is_hidden: false,
				is_actual: false,
				ctr: {
					yandex: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
					google: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
				},
				html: null
			}
		},
		closeDialog () {
			this.resetForm()
			this.$emit('closeDialog')
		},
		submitForm () {
			const isValid = this.$refs.form.validate()
			if (!isValid) {
				return
			}
			if ((this.dictionary && this.form.chapterParent && this.hasChanges) || (!this.dictionary && this.form.chapterParent)) {
				this.$emit('saveDictionary', this.form)
				this.closeDialog()
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.button{
	width: 97px;
	height: 40px;
	color: white;
	background-color: #2979FF !important;
	box-shadow: none !important;
	text-transform: none !important;
	margin-left: 12px;
}
.cancel-btn {
	background-color: #F5F5F5 !important;
	color:#455165;
}
.buttons {
	text-align: end;
}
.container {
	display: flex;
	margin: 0;
	padding: 12px 0 0 0;
}
.form-part {
	width: 524px;
}
.table-part {
	width: 440px;
}
.table {
	width: 100%;
	margin-left: 32px;
	border-collapse: collapse;

	&__header {
		background-color: #f5f5f5;

		&-item {
		padding: 10px;
		border: none;
		font-weight: 500;
		font-size: 12px;
		line-height: 16px;
		color: #455165;

			&:first-child {
				border-top-left-radius: 6px;
				border-bottom-left-radius: 6px;
			}
			&:last-child {
				border-top-right-radius: 6px;
				border-bottom-right-radius: 6px;
			}
		}
	}

	&__item {
		text-align: center;
		padding-top: 8px;
	}
}

.input-container {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 64px;
    border-radius: 6px;
    padding: 6px 8px;
    border: 1px solid #E0E6F0;
  }

  .input-ctr {
    width: 100%;
    font-size: 12px;
  }

  .percent-sign {
  margin-left: 5px;
  color:#E0E6F0;
}

.tree-label {
	margin-top: 20px;
}

.invalid {
	border: 2px solid red;
	border-radius: 4px;
}

</style>
